'use strict';

type SocialNetwork = 'email' | 'chat' | 'whatsapp' | 'facebook' | 'instagram' | 'twitter' | 'appstore' | 'playstore'
type MessageKind = 'private' | 'message'

interface Person {}

interface MessageAuthor {
  resource_id: string
  client_id: number
  uid: string
  name: string
  uname: string
  email?: string
  avatar?: string
  sn: SocialNetwork
  has_custom_data: boolean
  custom_fields: string[]
  cases_id?: string[]
  klout?: number
  influencer: boolean
  bot_enabled: boolean
  person?: Person
  social_accounts: number[]
  followers?: number
  crm_integration?: object
  gender: string
}

interface MessageRecipient {
  uid: string
  sn: SocialNetwork
  username?: string
}

interface CM {
  cmid: number
  name: string
  email?: string
  initials: string
}

interface Media {
  img_url: string
  metadata: object
  name: string
  type: string
  viewer_url: string
}

interface Transcription {
  text: string
  confidence: number
}

interface Reaction {
  emoji: string
  count: number
}

interface Author {
  name: string
  uid: string
}

interface QuotedMessage {
  mid: string
  text: string
  media?: Media
  author?: Author
}

interface ReferencePost {
  id: string
  title: string
  url: string
  account_name: string
}

interface ReferenceDetails {
  post: ReferencePost
}

interface Reference {
  type: string
  details: ReferenceDetails
}


interface Message {
  resource_id: string
  client_id: number
  mid: string
  text: string
  subject?: string
  sn: SocialNetwork
  kind: MessageKind
  url?: string
  created: string
  status: number
  author: MessageAuthor
  recipients: MessageRecipient[]
  thread_root?: string
  case_id: string
  sender_id: string
  recipient_id: string
  reply_to_msg: string
  is_mention: boolean
  level?: number
  ours: boolean
  published: boolean
  publisher: CM
  answered: boolean
  answered_by?: CM
  assigned: boolean
  mentions?: string[]
  current_assignment?: CM
  first_assignment?: CM
  first_assigned: boolean
  sentiment?: string
  hashtags: string[]
  search_hashtags: string[]
  following: string[]
  response_time?: number
  active: boolean
  hidden: boolean
  shares: number
  show_content: boolean
  media?: Media
  media_set?: Media[]
  attachments: Media[]
  no_cs_on_answer: boolean
  extra_data?: object
  transcription?: Transcription
  reactions?: Reaction[]

  quoted_message?: QuotedMessage

  reference?: Reference

  // Local use... not from backend
  beingReplied?: boolean
}

function messageAvHost(formData, desk_av) {
  if(!formData) return '';
  if(formData.has('image')) return desk_av;
  if(formData.has('document')) return desk_av;
  return '';
}

class MessageService {
  public onMessageSelected: rxjs.Subject<Message> = new rxjs.Subject()

  private AdRequest: AdRequestService

  constructor(AdRequest, DESK_AV) {
    this.AdRequest = AdRequest
    this.desk_av_host = DESK_AV
  }

  public selectMessage(message: Message) {
    this.onMessageSelected.next(message)
  }

  public get(messageID) {
    var path = '/api/v1/message/' + messageID + '/';
    return this.AdRequest.get(path);
  }

  public post(data, config) {
    var path = '/api/v1/messages/post/';
    return this.AdRequest.post(path, data, config, 0);
  }

  public edit(id, data, config) {
    var path = '/api/v1/message/' + id + '/';
    return this.AdRequest.put(path, data, config);
  }

  public publicReply(data, replyParameters) {
    const {
        httpConfig: config,
        sn: channel,
        provider: provider,
        formData: formData
    } = replyParameters;
    if (channel == undefined ||channel == "") {
      channel = "-"
    }
    if (provider == undefined || provider == "") {
      provider = "-"
    }
    var path = messageAvHost(formData, this.desk_av_host) + '/api/v1/messages/public_reply/';
    path = path + '?channel=' + channel + '&provider=' + provider;
    return this.AdRequest.post(path, formData || data, config, 0);
  }

  public privateReply(data, replyParameters) {
    const {
        httpConfig: config,
        sn: channel,
        provider: provider,
        formData: formData
    } = replyParameters;
    if (channel == undefined ||channel == "") {
      channel = "-"
    }
    if (provider == undefined || provider == "") {
      provider = "-"
    }
    var path = messageAvHost(formData, this.desk_av_host) + '/api/v1/messages/private_reply/';
    path = path + '?channel=' + channel + '&provider=' + provider;
    return this.AdRequest.post(path, formData || data, config, 0);
  };

  public assignCM(mid, data, config={}) {
    var path = '/api/v1/message/' + mid + '/';
    return this.AdRequest.put(path, data, config);
  };

  public addCustomData(mid, data, config={}) {
    var path = '/api/v1/message/' + mid + '/';
    return this.AdRequest.put(path, data, config);
  };

  public setSentiment(mid, data, config={}) {
    var path = '/api/v1/message/' + mid + '/';
    return this.AdRequest.put(path, data, config);
  };

  public share(mid, recipients, config={}) {
    var path = ['/api/v1/message/', mid, '/share/'].join('');
    return this.AdRequest.post(path, recipients, config)
  };

  public like(mid, config={}) {
    var path = ['/api/v1/message/', mid, '/like/'].join('');
    return this.AdRequest.post(path, config);
  };

  public hide(mid, config={}) {
    var path = ['/api/v1/message/', mid, '/hide/'].join('');
    return this.AdRequest.post(path, config);
  };

  public unhide(mid, config={}) {
    var path = ['/api/v1/message/', mid, '/unhide/'].join('');
    return this.AdRequest.post(path, config);
  };

  public delete(mid, config={}) {
    var path = ['/api/v1/message/', mid, '/'].join('');
    return this.AdRequest.delete(path, null, config, 0);
  };

  public getDomId(message) {
    return "message-" + message.resource_id;
  };

  public simulateMessage(
      accountUid: string,
      accountSocialNetwork: string,
      userUid: string,
      message: string,
      attachments: Any[],
      isInbound: boolean = true
    ) {
    var path = '/api/v2/tester/messages/';
    let data = {
      account_uid: accountUid,
      social_network: accountSocialNetwork,
      user_uid: userUid,
      message: message,
      inbound: isInbound,
      attachments: attachments
    }
    return this.AdRequest.post(path, data);
  };

}

var app = angular.module('postCenterWebClientApp');
app.service('Message', MessageService);
