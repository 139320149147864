interface AdRequestParams {
  baseURL: string,
  maxRetries: number,
  corsRetries: number,
  expFactor: number,
  env?: object,
  logout?: boolean
};

interface ErrorResponseDetail {
  detail: string
  code: number
}

interface ErrorResponseData {
  response: ErrorResponseDetail,
  status: number,
  method: string,
  url: string
}

class AdRequestService {
  private params: AdRequestParams;
  private $http: any;
  private $state: any;
  private AdStore: AdStore
  private AdNotification: AdNotification;
  private tokenSubject: rxjs.BehaviorSubject<string | null> = new rxjs.BehaviorSubject(undefined)
  private AppStateService: AppStateService
  
  public loggedOut: rxjs.Observable<void> = this.tokenSubject.pipe(
    rxjs.filter(token => token === null),
    rxjs.debounceTime(100)
  )
  public loggedIn: rxjs.Observable<void> = this.tokenSubject.pipe(
    rxjs.filter(token => token != null),
    rxjs.debounceTime(100)
  )

  constructor(
    $http, BASE_URL, $state, AdNotification, ENV, AdStore: AdStore, AppStateService: AppStateService
  ) {
    this.params = {
      baseURL: BASE_URL.replace('http:', window.location.protocol),
      maxRetries: 3,
      corsRetries: 6,
      expFactor: 2,
      env: ENV
    }
    this.$http = $http
    this.$state = $state
    this.AdNotification = AdNotification
    this.AdStore = AdStore
    this.AppStateService = AppStateService
  }

  private checkAuth(): Promise<boolean> {
    if (this.tokenSubject.value) {
      return Promise.resolve(true)
    }
    return this.getStoredSessionToken().then(token => {
      if (token) {
        return this.setToken(token).then(() => true)
      } else {
        return Promise.resolve(false)
      }
    })
  }

  public setToken(token: string | null): Promise<void> {
    return this.AdStore.set('_user', token).then(() => {
      let auth = token ? `Token ${token}` : null
      this.$http.defaults.headers.common.Authorization = auth
      if (this.tokenSubject.value !== token) {
        this.tokenSubject.next(token)
      }
    })
  }

  public getToken(): string {
    return this.tokenSubject.value
  }

  public getStoredSessionToken(): Promise<string> {
    return this.AdStore.get('_user')
  }

  getParams(): AdRequestParams {
    return this.params;
  }

  getEnv(): object | undefined {
    return this.params.env;
  }

  get<T>(path: string, config?, retry?: number, unauthenticated = false): Promise<T>  {
    return this.request('get', path, {}, config, retry, unauthenticated)
  }

  post<T>(path: string, body?, config?, retry?: number, unauthenticated = false): Promise<T> {
    return this.request('post', path, body, config, retry, unauthenticated)
  }

  put<T>(path: string, body?, config?, retry?: number, unauthenticated = false): Promise<T> {
    return this.request('put', path, body, config, retry, unauthenticated)
  }

  patch<T>(path: string, body?, config?, retry?: number, unauthenticated = false): Promise<T> {
    return this.request('patch', path, body, config, retry, unauthenticated)
  }

  delete<T>(path: string, body?, config?, retry?: number, unauthenticated = false): Promise<T> {
    return this.request('delete', path, body, config, retry, unauthenticated)
  }

  login(user: string, pass: string, captchaToken: string): Promise<string> {
    return this.post<{token: string}>(
      '/api/v1/login/',
      {username: user, password: pass, response_user: captchaToken},
      {Authorization: ''},
      0,
      true
    ).then(resp => {
      let token = resp.token
      return this.setToken(token).then(() => token)
    })
  }

  logout<R>(): Promise<R> {
    let logoutPromise = this.$http.get(`${this.params.baseURL}/api/v1/logout`)
    this.signalLogout()
    return logoutPromise
  }

  private signalLogout() {
    this.setToken(null)
  }

  private request<P, C, R>(
    requestMethod: string, path: string, body?: P, config?: C,
    retry: number = 3, unauthenticated = false
  ): Promise<R> {
    if (!unauthenticated) {
      return this.checkAuth().then(loggedIn => {
        if (!loggedIn) {
          this.AppStateService.goToLogin();
          return Promise.reject({
            detail: 'logged out',
            status: 401,
            request: {'method': requestMethod, 'path': path}
          })
        } else {
          return this.doRequest(requestMethod, path, body, config, retry)
        }
      })
    }
    return this.doRequest(requestMethod, path, body, config, retry)
  }

  private doRequest<P, C, R>(
    requestMethod: string, path: string, body?: P, config?: C, retry: number = 3
  ): Promise<R> {
    const request = this.$http[requestMethod]
    const {baseURL}: AdRequestParams = this.params;

    const url = path.startsWith('https://') ? path : `${baseURL}${path}`;

    return request(url, body, Object.assign({withCredentials: true}, config))
      .then(resp => resp.data)
      .catch(error => {
        const {
          status,
          data: response,
          config: {url, method}
        } = error;
        const errorResponse: ErrorResponseData = {response, status, method, url};

        if (status === -1 || status === 0 || status >= 500) {
          if (retry > 0) {
            return this.doRequest(
              requestMethod,
              path,
              body,
              config,
              retry - 1
            );
          }
        }
        if (status == 402) {
          if(
            !this.AppStateService.currentState().isInboxState() &&
            !this.AppStateService.currentState().isBillingState()
          ) {
            this.$state.go('crm.inbox');
          }
          return Promise.resolve([]); // successful redirect
        }
        this._errorHandler(errorResponse)
        return Promise.reject(errorResponse)
      })
  }

  _errorHandler(errorData: ErrorResponseData): void {
    const detail: string = errorData?.response?.detail;
    const code: number = errorData?.response?.code;
    const status: number = errorData?.status;
    const ERROR_INVALID_PARTNER_URL = 10999;

    if(status == 401 && code == ERROR_INVALID_PARTNER_URL) {
        this.AdNotification.error(errorData, 'backend_response');
        this.signalLogout()
        setTimeout(() =>  location.replace("https://" + errorData.response.default), 5000);
    }

    this.checkAuth().then(loggedIn => {
      if (detail && status === 401 && loggedIn) {
        if (
          detail === 'Invalid token' ||
          detail === 'Token expired' ||
          detail === 'User inactive or deleted'
        ) {
          const message: string =
            detail === 'Invalid token' || detail === 'Token expired'
              ? 'other_user_steal_session'
              : 'user_was_disabled';
          this.AdNotification.error({status}, message);
          this.signalLogout()
        }
      }
    })
  }
}

angular
  .module('postCenterWebClientApp')
  .service('AdRequest', AdRequestService);
