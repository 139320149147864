(function(module) {
  'use strict';
  FireTagService.$inject = ['FIREBASE','AdEncodingUtils'];
  module.factory('FireTagService', FireTagService);
  function FireTagService(FIREBASE, AdEncodingUtils) {
    // FIREBASE object values must be decoded from base64 encoding
    const { measurementId } = AdEncodingUtils.decodeObject(FIREBASE)

    return {
      config: {},
      setPageView({title, path}) {
        gtag('config', measurementId, {
          page_title: title,
          page_path: path,
          send_page_view: true,
          ...this.config
        });
        mixpanel.track(
          'visit_page_'+title, {
            "page_title": title,
            "page_path": path,
            "send_page_view": true,
            ...this.config
          })
        ;
      },
      setEvent({name, params = {}}) {
        gtag('event', name, {...this.config, ...params});
        mixpanel.track(
          'event_'+name, {
            "name":name,
            ...this.config, ...params
          });
      },
      setGlobalConfig(params = {}) {
        this.config = {...this.config, ...params};
      }
    };
  }
})(angular.module('postCenterWebClientApp'));
