class AdEncodingUtils {
  $resource

  constructor($resource) {
    this.$resource = $resource
  }

  public decodeObject(str: string): Object {
    const decoded = decodeURIComponent(escape(atob(str)))
    return JSON.parse(decoded)
  };
}
angular
  .module('postCenterWebClientApp')
  .service('AdEncodingUtils', AdEncodingUtils);
